import _defaults from 'lodash/defaults.js';

import {IS_MOBILE} from '../env.js';
import {ding} from '../utils/SoundEffects.js';

import FireworkEffect from '../threejs/particles/FireworkEffect.js';
import HeartsEffect from '../threejs/particles/HeartsEffect.js';

const DEFAULT_PARAMS = {
  // delimits the milestone through time
  timeFrom: 0,      // time (in seconds) where the milestone starts
  loopFrom: null,   // time (in seconds) where the milestone loop sequence starts
  timeTo: 0,        // time (in seconds) where the milestone ends
  loopTo: null,     // time (in seconds) where the milestone loop sequence ends

  // determines what needs to be done to complete the milestone
  validatorFunc: null,              // a function that must return true
  validatorHandPoses: null,         // array of hand poses idents, at least one of them must be performed
  validatorZone: null,              // a zone in which hand pose must be performed
  validatorHandZoneOrigin: 'palm',  // ( palm | index ) which part of the user's hand will perfom the interaction in the interactive zone
  validatorHoldTime: 1000,          // the amount of milliseconds a user need to keep the valid state until it's registered

  // some events callbacks
  onActivatedCallback: null,

  // others
  skipFeedback: false,
  successEffect: 'firework', // ( firework | hearts ) the effect to display after success
};

/**
 * Represents a milestone inside a video timeline
 */
class VideoMilestone {
  constructor(params = {}) {
    params = _defaults(params, DEFAULT_PARAMS);

    this.timeFrom = params.timeFrom;
    this.loopFrom = typeof params.loopFrom === 'number' ? params.loopFrom : params.timeFrom;
    this.timeTo = params.timeTo;
    this.loopTo = typeof params.loopTo === 'number' ? params.loopTo : params.timeTo;

    this.validatorFunc = params.validatorFunc;
    this.validatorHandPoses = params.validatorHandPoses;
    this.validatorZone = params.validatorZone;
    this.validatorHandZoneOrigin = params.validatorHandZoneOrigin;
    this.validatorHoldTime = params.validatorHoldTime;

    this.onActivatedCallback = params.onActivatedCallback;

    this.skipFeedback = params.skipFeedback;
    this.successEffect = params.successEffect;

    // note: not really a problem, but might be an undesired error
    if (this.loopFrom < this.timeFrom || this.loopTo > this.timeTo) {
      console.warn('Milestone: loop is outside time limits.');
    }

    this._hasCompletionRequirements = !!(this.validatorFunc || this.validatorHandPoses || this.validatorZone);
    this._hasUserInteractionRequirements = !!(this.validatorHandPoses || this.validatorZone);
  }

  hasCompletionRequirements() {
    return this._hasCompletionRequirements;
  }

  triggerActivated(skipped = false) {
    if (typeof this.onActivatedCallback === 'function') {
      this.onActivatedCallback(skipped);
    }
  }

  triggerCompleted() {
    if (this.skipFeedback) {
      return;
    }

    // @TODO maybe trigger a different sounds per milestone ? (related to the interaction performed)
    ding();

    if (this._hasUserInteractionRequirements && IS_MOBILE && typeof navigator.vibrate === 'function') {
      setTimeout(() => { navigator.vibrate(150); }, 100);
    }
  }

  getSuccessEffect() {
    switch (this.successEffect) {
      case 'hearts':
        return new HeartsEffect();
      break;

      case 'firework':
      default:
        return new FireworkEffect();
      break;
    }
  }
}

export default VideoMilestone;
