/**
 * Represents a circle zone inside a video timeline
 */
class VideoZoneCircle {
  constructor(centerX = 0, centerY = 0, radius = 0.35) {
    // [from -1 to 1] 0 is center
    this.centerX = centerX; // -1 = completely left, 1 = completely right
    this.centerY = centerY; // -1 = completely bottom, 1 = completely top

    // note: how much from the height of the video
    this.radius = radius;
  }
}

export default VideoZoneCircle;
