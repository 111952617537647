import {Vector3, Group} from 'three';
import {IS_PROD, IS_MOBILE} from '../env.js';
import CONFIG from '../config.js';
import CONTEXT from '../context.js';
import {valueFromCenteredToThreejs, posFromCenteredToThreejs, setViewVisibility} from '../helpers.js';
import ViewportManager from '../managers/ViewportManager.js';
import PosesManager from '../managers/PosesManager.js';
import PalmCentersManager from '../managers/PalmCentersManager.js';
import TimeAccumulator from '../utils/TimeAccumulator.js';
import InteractiveCircle from '../threejs/view/InteractiveCircle.js';

const _vecA = new Vector3();
const _vecB = new Vector3();

const USER_HAND_CIRCLE_FINGER_RADIUS = 0.15;
const USER_HAND_CIRCLE_PALM_RADIUS = 0.4;
const HALF_MOVE_RANGE = CONFIG.HAND_MOVEMENT_RANGE * 0.5;

let _desiredHandCircleRadius = USER_HAND_CIRCLE_PALM_RADIUS;

const project3dTo2d = (vec3, camera) => {
  // to normalized device coordinate
  vec3.project(camera);

  // compensate that the camera is not squared, but our calculations are afterward
  if (camera.aspect < 1) {
    vec3.x *= camera.aspect;
  } else {
    vec3.y *= 1 / camera.aspect;
  }

  // back to threejs space
  vec3.x = ((   vec3.x + 1 ) * CONFIG.HAND_MOVEMENT_RANGE) * 0.5;
  vec3.y = (( - vec3.y + 1 ) * CONFIG.HAND_MOVEMENT_RANGE) * 0.5;
  vec3.z = 0;
};

class VideoTimeline {
  constructor(videoPlayer, scene, milestones = []) {
    this._videoPlayer = videoPlayer;
    this._scene = scene;
    this._milestones = milestones;
    this._maxRetries = Infinity;

    this._isStarted = false;

    this._currentRetries = 0;
    this._currentMilestoneIndex = 0;
    this._currentMilestoneVisual = null;
    this._currentMilestoneActivated = false;
    this._currentMilestoneTimeAccumulator = new TimeAccumulator();

    this._uiUserLayerGroup = new Group();
    this._uiWebsiteLayerGroup = new Group();
    this._scene.mainContainer.add(this._uiUserLayerGroup);
    this._scene.websiteScrollContainer.add(this._uiWebsiteLayerGroup);

    this._interactiveCircle = new InteractiveCircle();
    this._interactiveCircle.material = this._interactiveCircle.material.clone();
    this._interactiveCircle.position.z = valueFromCenteredToThreejs(0, 'z');
    this._interactiveCircle.visible = false;
    this._interactiveCircle.renderOrder = 99999;
    this._uiWebsiteLayerGroup.add(this._interactiveCircle);

    this._userHandCirclesMap = new Map();

    ViewportManager.on('visibility-changed', this._onVisibilityChanged.bind(this));

    const registerEventsListeners = () => {
      // @TODO must unregister those if I where to dispose of an instance of this class
      this._videoPlayer.on('video-timeupdate', this._tickUpdate.bind(this));
      this._videoPlayer.on('video-ended', this._onVideoEnded.bind(this));
      this._videoPlayer.on('covering-ratio-changed', this._onCoveringRatioChanged.bind(this));
    };

    // debug stuff
    if (CONFIG.VID_SEQUENCE_DEBUG_MODE) {
      // this._videoPlayer._videoElem.muted = true;
      // this._videoPlayer._videoElem.controls = true;
      this._videoPlayer.updateSettings({
        playbackRate: 3,
      });
    }

    // @TODO this won't always work, it's the first interactive milestone in Sarah's video, but maybe I should
    // create a milestone myself to test with ?
    if (CONFIG.DEBUG_INTERACTIVE_CIRCLE) {
      const milestone = this._milestones[4]; // high five
      // const milestone = this._milestones[5]; // nose touch
      // const milestone = this._milestones[6]; // heart shape
      this._setMilestoneVisuals(milestone);
      this._updateMilestoneVisuals(milestone);
      this._interactiveCircle.visible = true;
      const milestoneMiddleTime = milestone.timeFrom + ((milestone.timeTo - milestone.timeFrom) * 0.5);
      this._videoPlayer.seek(milestoneMiddleTime);

      // note: must register them later to make sure my seek does not get cancelled because the timeline
      // tries to go back at the begining of the first milestone
      setTimeout(registerEventsListeners, 1000);
    } else {
      registerEventsListeners();
    }

    if (!IS_PROD) {
      window.addEventListener('keydown', (evt) => {
        if (evt.key === ' ') {
          const currentMilestone = this._milestones[this._currentMilestoneIndex];
          if (currentMilestone) {
            evt.preventDefault();
            console.log('SKIP', this._currentMilestoneIndex);
            this._activateMilestoneIfNeeded(currentMilestone, true);
            this._successMilestone(currentMilestone);
          }
        }
      });
    }
  }

  start() {
    this._isStarted = true;
    this._videoPlayer.play();
  }

  stop() {
    // ...@TODO
  }

  reset() {
    // console.log('reset');
    this._currentRetries = 0;
    this._currentMilestoneIndex = 0;
    this._currentMilestoneActivated = false;
    this._currentMilestoneTimeAccumulator.reset();
  }

  _getUserHandCircle(handRig) {
    let circle = this._userHandCirclesMap.get(handRig);
    if (!circle) {
      circle = new InteractiveCircle();
      circle.material = circle.material.clone();
      circle.material.color.set(0xeb659e);
      circle.position.z = this._interactiveCircle.position.z * 0.95;
      this._scaleInteractiveCircle(circle, _desiredHandCircleRadius);
      circle.renderOrder = 99999;
      this._uiUserLayerGroup.add(circle);

      const baseOpacity = circle.material.opacity;

      Object.defineProperty(circle, 'visible', {
        get() {
          return handRig.isVisible;
        },
      });
      Object.defineProperty(circle.material, 'opacity', {
        get() {
          return handRig._skinnedMesh.material.opacity * baseOpacity;
        },
      });

      this._userHandCirclesMap.set(handRig, circle)
    }

    return circle;
  }

  _positionInteractiveCircleAtZone(interactiveCircle, zone) {
    let centerX = zone.centerX;
    let centerY = zone.centerY;

    // decal video if I think the zone will be outside the (vertical) screen (on a phone mostly)
    // NOTE: the code chunk below will change the value of centerX and centerY !
    if (this._videoPlayer.coverWholeBackground) {
      let decalX = 0;
      let decalY = 0;

      if (CONFIG.LEGACY_CENTERING) {
        const ratio = CONTEXT.APP.viewportRatio;
        if (ratio < 1) {
          decalX = centerX * (1 - ratio);
          centerX *= ratio;
        } else if(ratio > 2.5) {
          // @TODO this calculation seems a bit off
          decalY = centerY * (1 - (1 / ratio));
          centerY *= (1 / ratio);
        }
      }

      else if(this._videoPlayer._latestResizeSpecs) {
        decalX = centerX;
        decalY = centerY;

        const contentWidth = this._videoPlayer._latestResizeSpecs.contentWidth;
        const containerWidth = this._videoPlayer._containerElem.offsetWidth;
        const contentHeight = this._videoPlayer._latestResizeSpecs.contentHeight;
        const containerHeight = this._videoPlayer._containerElem.offsetHeight;

        // @TODO to be honest, I have no idea why it works with those 2 numbers...
        const magicNumberA = this._videoPlayer.videoRatio; // 1.5
        const magicNumberB = 0.95;

        if (contentWidth > containerWidth + 1) {
          const excess = contentWidth - containerWidth;
          const diff = (excess / contentWidth) * centerX * magicNumberA;
          centerX -= diff;
        }

        if (contentHeight > containerHeight + 1) {
          const excess = contentHeight - containerHeight;
          const diff = (excess / contentHeight) * centerY * magicNumberB;
          centerY -= diff;
        }
      }

      this._videoPlayer.setDecal(decalX, CONFIG.LEGACY_CENTERING ? undefined : -decalY);
    }

    interactiveCircle.position.x = valueFromCenteredToThreejs(centerX, 'x');
    interactiveCircle.position.y = valueFromCenteredToThreejs(centerY, 'y');

    if (CONFIG.LEGACY_CENTERING) {
      interactiveCircle.position.x *= this._videoPlayer._videoCoveringWidthRatio;
      interactiveCircle.position.y *= this._videoPlayer._videoCoveringHeightRatio;
    } else {
      interactiveCircle.position.x *= CONFIG.HAND_LOCAL_SCALE;
      interactiveCircle.position.y *= CONFIG.HAND_LOCAL_SCALE;
    }

    this._scaleInteractiveCircle(interactiveCircle, zone.radius);
  }

  _scaleInteractiveCircle(interactiveCircle, radius = 1) {
    if (CONFIG.LEGACY_CENTERING) {
      radius *= this._videoPlayer._videoCoveringHeightRatio;
    } else {
      radius *= CONFIG.HAND_LOCAL_SCALE;
    }

    interactiveCircle.scale.set(radius, radius, radius);
  }

  _updateUserCirclesScale() {
    const currentMilestone = this._milestones[this._currentMilestoneIndex];

    _desiredHandCircleRadius = currentMilestone && currentMilestone.validatorHandZoneOrigin === 'index' ? USER_HAND_CIRCLE_FINGER_RADIUS : USER_HAND_CIRCLE_PALM_RADIUS;

    this._userHandCirclesMap.forEach((value) => {
      this._scaleInteractiveCircle(value, _desiredHandCircleRadius);
    });
  }

  _setMilestoneVisuals(milestone = null) {
    if (milestone && this._currentMilestoneVisual !== milestone) {
      this._updateMilestoneVisuals(milestone);
      this._currentMilestoneVisual = milestone;
    } else if(!milestone && this._currentMilestoneVisual) {
      this._interactiveCircle.visible = false;
      this._currentMilestoneVisual = null;
      setViewVisibility(this._uiUserLayerGroup, false);
      setViewVisibility(this._uiWebsiteLayerGroup, false);
    }
  }

  _updateMilestoneVisuals(milestone) {
    if (milestone.validatorZone) {
      this._positionInteractiveCircleAtZone(this._interactiveCircle, milestone.validatorZone);
      this._interactiveCircle.visible = true;
      setViewVisibility(this._uiUserLayerGroup, true, 7000);
      setViewVisibility(this._uiWebsiteLayerGroup, true, 7000);
    }
  }

  _isMilestoneMet(milestone) {
    // skip when no requirements
    if (!milestone.hasCompletionRequirements()) {
      return true;
    }

    const validatorFunc = milestone.validatorFunc;
    const validZone = milestone.validatorZone;
    const validHandPoses = milestone.validatorHandPoses;
    const validHandZoneOrigin = milestone.validatorHandZoneOrigin;

    // check if the validator function returns false
    if (validatorFunc && !validatorFunc()) {
      return false;
    }

    // check if any hand is in desired zone
    if (validZone && !this._isHandInZone(validZone, validHandZoneOrigin)) {
      this._interactiveCircle.material.color.set(0x0adef0);
      return false;
    } else {
      this._interactiveCircle.material.color.set(0xeb659e);
    }

    // check if any hand is doing any desired pose
    if (validHandPoses) {
      let atLeastOneMatch = false;
      for (let i = 0; i < validHandPoses.length; i++) {
        if (PosesManager.isPoseCurrentlyMade(validHandPoses[i])) {
          atLeastOneMatch = true;
          break;
        }
      }

      if (!atLeastOneMatch) {
        return false;
      }
    }

    return true;
  }

  _isHandInZone(zone, origin) {
    const palmCentersMap = origin === 'index' ? PalmCentersManager.getIndexFingersPositions() : PalmCentersManager.getPalmCenters();
    const palmRigs = Array.from(palmCentersMap.keys());
    const palmCenters = Array.from(palmCentersMap.values());

    let handRig, centerPos, handCircle;
    for (let i = 0; i < palmRigs.length; i++) {
      handRig = palmRigs[i];

      if (!handRig.isVisible) {
        continue;
      }

      handCircle = this._getUserHandCircle(handRig);
      centerPos = palmCenters[i].clone();

      // constrain inside video size
      // centerPos.x = Math.min(Math.max(centerPos.x, -this._videoPlayer._videoCoveringWidthRatio), this._videoPlayer._videoCoveringWidthRatio);
      // centerPos.y = Math.min(Math.max(centerPos.y, -this._videoPlayer._videoCoveringHeightRatio), this._videoPlayer._videoCoveringHeightRatio);

      // show circle at center
      handCircle.position.copy(posFromCenteredToThreejs(centerPos));
      handCircle.position.z *= 1.1;
      // handCircle.position.z = this._interactiveCircle.position.z; // note : circles wont appear to touch if they are not on same z depth

      // @TODO this is using the views to calculate collisions, this could be done using only math from data itself !
      // check if hand zone is inside interactive zone
      this._interactiveCircle.getWorldPosition(_vecA);
      handCircle.getWorldPosition(_vecB);

      // note: compare on same depth otherwise calculation will be wrong
      project3dTo2d(_vecA, this._scene.mainCamera);
      project3dTo2d(_vecB, this._scene.mainCamera);

      const distance = _vecA.distanceTo(_vecB);

      // 2 circles overlap partially
      // const maxDistance = HALF_MOVE_RANGE * (this._interactiveCircle.scale.y + handCircle.scale.y);

      // 2 circles overlap completely
      // const maxDistance = HALF_MOVE_RANGE * (this._interactiveCircle.scale.y - handCircle.scale.y);

      // central point inside circle
      const maxDistance = HALF_MOVE_RANGE * this._interactiveCircle.scale.y;

      if (distance < maxDistance) {
        return true;
      }
    }

    return false;
  }

  _activateMilestoneIfNeeded(milestone, skipped = false) {
    if (!this._currentMilestoneActivated) {
      milestone.triggerActivated(skipped);
      this._currentMilestoneActivated = true;
    }
  }

  _retryMilestone(milestone) {
    // console.log('retry');
    this._videoPlayer.seek(milestone.loopFrom);
    this._currentRetries++;
  }

  _successMilestone(milestone) {
    if (!milestone.skipFeedback && milestone.validatorZone) {
      const effect = milestone.getSuccessEffect();
      effect.position.copy(this._interactiveCircle.position);
      this._scene.websiteScrollContainer.add(effect);
    }

    this._setMilestoneVisuals();
    // console.log('SUCCESS !');

    milestone.triggerCompleted();

    this._videoPlayer.seek(milestone.timeTo);
    this._goToNextMilestone();
  }

  _goToNextMilestone() {
    // console.log('next');
    this._currentMilestoneIndex++;
    this._currentMilestoneActivated = false;
    this._currentMilestoneTimeAccumulator.reset();
    this._currentRetries = 0;
    setTimeout(() => {
      this._videoPlayer.resetDecal();
    }, 800);
    this._updateUserCirclesScale();

    const currentMilestone = this._milestones[this._currentMilestoneIndex];
    if (currentMilestone) {
      this._currentMilestoneTimeAccumulator.setMaxTime(currentMilestone.validatorHoldTime);
    }
  }

  _tickUpdate() {
    const currentTime = this._videoPlayer._videoElem.currentTime;
    const currentMilestone = this._milestones[this._currentMilestoneIndex];

    if (currentMilestone && currentTime > currentMilestone.timeFrom) {
      // activate milestone when reached for the first time
      this._activateMilestoneIfNeeded(currentMilestone);

      this._setMilestoneVisuals(currentMilestone);

      // check if the user has done what is needed
      if (this._isMilestoneMet(currentMilestone)) {
        this._currentMilestoneTimeAccumulator.tickPositive();
      } else {
        this._currentMilestoneTimeAccumulator.tickNegative();
      }

      if (this._currentMilestoneTimeAccumulator.isCompleted()) {
        this._successMilestone(currentMilestone);
        return;
      }

      // handle milestone surpassed
      if (currentTime > currentMilestone.loopTo) {
        if (this._currentRetries < this._maxRetries) {
          this._retryMilestone(currentMilestone);
        } else {
          this._goToNextMilestone();
        }
      }
    }
  }

  _onVideoEnded() {
    this.reset();

    this._isStarted = false;

    const action = IS_MOBILE ? 'Tap' : 'Click';
    const message = action + ' to replay';

    CONTEXT.APP.requestUserAction(message).then(() => {
      this.start();
    });
  }

  _onVisibilityChanged(visible) {
    if (this._isStarted) {
      if (visible) {
        this._videoPlayer.play();
      } else {
        this._videoPlayer.pause();
      }
    }
  }

  _onCoveringRatioChanged() {
    if (this._currentMilestoneVisual) {
      this._updateMilestoneVisuals(this._currentMilestoneVisual);
    }

    this._updateUserCirclesScale();
  }
}

export default VideoTimeline;
