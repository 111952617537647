import {
  CircleGeometry,
  MeshBasicMaterial,
  DoubleSide,
  AdditiveBlending,
  Mesh,
} from 'three';

import CONFIG from '../../config.js';

const GEOMETRY = new CircleGeometry(CONFIG.HAND_MOVEMENT_RANGE * 0.5, 32);

const MATERIAL = new MeshBasicMaterial({
  color: 0x0adef0,
  transparent: true,
  opacity: 0.65,
  alphaToCoverage: true,
  side: DoubleSide,
  blending: AdditiveBlending,
  toneMapped: false,
});

class InteractiveCircle extends Mesh {
  constructor() {
    super(GEOMETRY, MATERIAL);
  }
}

export default InteractiveCircle;
