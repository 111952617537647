const TIME_HANDLE = performance || Date;

class TimeAccumulator {
  constructor(maxTime = 1000) {
    this._maxTime = maxTime;
    this._accumulatedTime = 0;
    this._lastTime = null;
  }

  isCompleted() {
    return this._accumulatedTime === this._maxTime;
  }

  reset() {
    this._accumulatedTime = 0;
    this._lastTime = null;
  }

  setMaxTime(maxTime = 1000) {
    this._maxTime = maxTime;
  }

  tickPositive() {
    this._accumulatedTime += this._tickCurrentTime();

    if (this._accumulatedTime > this._maxTime) {
      this._accumulatedTime = this._maxTime;
    }
  }

  tickNegative() {
    this._accumulatedTime -= this._tickCurrentTime();

    if (this._accumulatedTime < 0) {
      this._accumulatedTime = 0;
    }
  }

  _tickCurrentTime() {
    if (this._lastTime === null) {
      this._lastTime = TIME_HANDLE.now();
      return 0;
    }

    const currentTime = TIME_HANDLE.now();
    const deltaTime = currentTime - this._lastTime;
    this._lastTime = currentTime;

    return deltaTime;
  }
}

export default TimeAccumulator;
