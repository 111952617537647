import CONTEXT from '../context.js';
import CONFIG from '../config.js';
import CoreTracking from '../core/CoreTracking.js';
import TimeAccumulator from '../utils/TimeAccumulator.js';

import handPhoneImagePath from '../assets/hand-phone.png';
import handWaveImageRightPath from '../assets/hand-wave-right.png';
import handWaveImageLeftPath from '../assets/hand-wave-left.png';

/**
 * Singleton class to handle the display of some tutorial-type things
 */
class TutorialsManager {
  constructor() {
    this._userLearnedToMoveHisHands = false;
  }

  teachUserToMoveHisHands(force = false) {
    if (force && this._userLearnedToMoveHisHands) {
      this._userLearnedToMoveHisHands = false;
    }

    return new Promise((resolve, reject) => {
      // already learnt
      if (this._userLearnedToMoveHisHands) {
        resolve();
      }

      // must learn it
      else {
        let hasLearned = false;
        let interactionInstructions = null;
        const timeAccumulator = new TimeAccumulator(500);

        const interactionListener = CoreTracking.on('tracking-data-received', (handsData) => {
          if (handsData && handsData.length) {
            timeAccumulator.tickPositive();
          } else {
            timeAccumulator.tickNegative();
          }

          if (timeAccumulator.isCompleted()) {
            hasLearned = true;
            this._userLearnedToMoveHisHands = true;
            interactionListener.off();

            if (interactionInstructions) {
              setTimeout(() => {
                interactionInstructions.destroy();
                CONTEXT.SHOWING_HAND_TUTO = false;
                resolve();
              }, 500);
            } else {
              resolve();
            }
          }
        });

        setTimeout(() => {
          if (!hasLearned) {
            CONTEXT.SHOWING_HAND_TUTO = true;
            interactionInstructions = this._showHandPoseTuto();
          }
        }, 1500);
      }
    });
  }

  _showHandPoseTuto() {
    const div = document.createElement('div');
    div.classList.add('hand-position-tuto');

    const anim = document.createElement('div');
    anim.classList.add(
      'hand-anim',
      CONFIG.FRONT_FACING_CAMERA ? 'front-facing' : 'rear-facing'
    );
    div.appendChild(anim);

    const img1 = document.createElement('img');
    img1.classList.add('hand-phone');
    img1.src = handPhoneImagePath;
    anim.appendChild(img1);

    const img2 = document.createElement('img');
    img2.classList.add('hand-wave');
    img2.src = CONFIG.FRONT_FACING_CAMERA ? handWaveImageLeftPath : handWaveImageRightPath;
    anim.appendChild(img2);

    const text = document.createElement('p');
    const whichCamera = CONFIG.FRONT_FACING_CAMERA ? 'front' : 'rear';
    text.classList.add('hand-instructions');
    text.innerHTML = 'Raise you hand into<br>the '+ whichCamera +' camera view';
    anim.appendChild(text);

    return CONTEXT.APP.showUserHtml(div);
  }
}

export default new TutorialsManager();
