import CONTEXT from '../src/context.js';
import CONFIG from '../src/config.js';
import LogsManager from '../src/managers/LogsManager.js';
import MetaHandApp from '../src/app/MetaHandApp.js';

// @TODO this won't hide the html of the website though
// document.body.classList.add('full-screen-demo-mode');

if (CONFIG.GOD_MODE) {
  document.body.classList.add('god-mode');
}

CONTEXT.APP = new MetaHandApp();

// pick the scene we want shown here
// import ChoosenScene from '../src/threejs/scenes/AdultsIndustryScene.js';
import ChoosenScene from '../src/threejs/scenes/FullscreenVideoScene.js';
// import ChoosenScene from '../src/threejs/scenes/TechDemoScene.js';
// import ChoosenScene from '../src/threejs/scenes/PhysicsDemoScene.js';
// import ChoosenScene from '../src/threejs/scenes/TonyDemoScene.js';

// import ChoosenScene from '../src/threejs/scenes/HandsOverlayScene.js';

// TODO: pull this from package.json or config.js
LogsManager.logAppVersion('0.2.0');

CONTEXT.APP.startApp(ChoosenScene);
