import {IS_MOBILE} from '../../env.js';
import CONFIG from '../../config.js';
import CONTEXT from '../../context.js';
import TutorialsManager from '../../managers/TutorialsManager.js';
import LoadingManager from '../../managers/LoadingManager.js';
import UserCamera from '../../medias/UserCamera.js';

import VideoMilestone from '../../medias/VideoMilestone.js';
import VideoZoneCircle from '../../medias/VideoZoneCircle.js';

import videoPath from '/src/assets/videos/video-interaction-sarah.mp4';

let userHasMovedHisHands = false;
let userHasEnabledCamera = false;

export default {
  videoPath: videoPath,
  videoPlayerParams: {
    coverWholeBackground: true,
    flipHorizontal: false,
    decalX: 0.02,
    decalY: -0.6,
  },
  videoAudioGain: 2,
  handDepthCenter: 1,
  milestones: [
    // loading period
    new VideoMilestone({
      timeFrom: 0,
      timeTo: 4.75,
      validatorFunc: LoadingManager.isFullyLoaded.bind(LoadingManager),
      skipFeedback: true,
    }),

    // skip "To continue the demonstration..."
    new VideoMilestone({
      timeFrom: 12.5,
      timeTo: 16.05,
      skipFeedback: true,
    }),

    // camera permission instructions (optional)
    new VideoMilestone({
      timeFrom: 16.5,
      loopFrom: 25,
      loopTo: 33,
      // timeTo: 35,
      timeTo: 44,
      validatorFunc: () => {
        return userHasEnabledCamera && UserCamera.isReady();
      },
      onActivatedCallback: (skipped) => {
        userHasEnabledCamera = false;
        setTimeout(async () => {
          await CONTEXT.APP.initializeCameraVideo();
          CONTEXT.APP.startCameraTracking();
          userHasEnabledCamera = true;
        }, skipped || CONFIG.VID_SEQUENCE_DEBUG_MODE ? 0 : 4250);
      },
      skipFeedback: true,
    }),

    // raise hand in camera view
    new VideoMilestone({
      // timeFrom: 35.5, // right after the camera permission
      // timeFrom: 42.5, // after the little bit of text she speaks
      timeFrom: 53, // after she asks to raise your hand
      loopFrom: 55,
      loopTo: 60 + 8,
      // timeTo: 60 + 21.5,
      // timeTo: 60 + 10,
      timeTo: 60 + 55,
      validatorFunc: () => {
        return userHasMovedHisHands || CONFIG.VID_SEQUENCE_DEBUG_MODE;
      },
      onActivatedCallback: (skipped) => {
        if (!skipped) {
          userHasMovedHisHands = false;
          TutorialsManager.teachUserToMoveHisHands(true).then(() => {
            userHasMovedHisHands = true;
          });
        } else {
          userHasMovedHisHands = true;
        }
      },
      skipFeedback: true,
    }),

    // note: no need to skip now that I go directly where I want in the previous step above
    // chop practice period, it's too long
    // new VideoMilestone({
    //   timeFrom: 60 + 10, // from "That’s great, now you can see your Meta-hand..."
    //   // timeFrom: 60 + 40, // useless silence practice period
    //   timeTo: 60 + 55,
    //   skipFeedback: true,
    // }),

    // high five
    new VideoMilestone({
      timeFrom: 60 + 60 + 6,
      loopFrom: 60 + 60 + 13,
      timeTo: 60 + 60 + 46,
      validatorHandPoses: ['open-hand', 'open-hand-spread'],
      validatorZone: new VideoZoneCircle(0.42, 0.43, 0.4),
      validatorHoldTime: 500,
    }),

    // touch nose
    new VideoMilestone({
      timeFrom: 60 + 60 + 59,
      timeTo: 60 + 60 + 60 + 34,
      // validatorHandPoses: ['pointer-hand'],
      validatorZone: new VideoZoneCircle(-0.04, 0.42, 0.15),
      validatorHandZoneOrigin: 'index',
      validatorHoldTime: 1000,
    }),

    // heart shape
    new VideoMilestone({
      timeFrom: 60 + 60 + 60 + 53,
      timeTo: 60 + 60 + 60 + 60,
      // validatorHandPoses: ['pointer-hand', 'pistol-hand'],
      validatorZone: new VideoZoneCircle(0.15, 0.5, 0.39),
      validatorHandZoneOrigin: 'index',
      validatorHoldTime: 1500,
      successEffect: 'hearts',
    }),
  ],
};
