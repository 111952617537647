import CONFIG from '../../config.js';
import CONTEXT from '../../context.js';
import AbstractScene from './AbstractScene.js';
import VideoPlayer from '../../medias/VideoPlayer.js';
import VideoTimeline from '../../medias/VideoTimeline.js';

// russell's sequence
// import choosenSequence from '../../medias/sequences/SampleSequence.js';

// sarah's sequence
import choosenSequence from '../../medias/sequences/SarahSequence.js';

/**
 * A scene with transparent background that let us see the fullscreen video behind it
 */
class FullscreenVideoScene extends AbstractScene {

  constructor() {
    super(...arguments);

    CONFIG.HAND_DEPTH_CENTER = choosenSequence.handDepthCenter || 0;

    this.bgVideoPlayer = new VideoPlayer(choosenSequence.videoPlayerParams);
    this.bgVideoPlayer._containerClasses.push('fullscreen-under');
    this.bgVideoPlayer.injectInDOM(CONTEXT.APP._domContainer); // @TODO accessing private prop, should do better !
    this.bgVideoPlayer.enableHandSizeControl(0.6);
    this._bgVideoLoadPromise = this.bgVideoPlayer.setSrcUrl(choosenSequence.videoPath).then(() => {
      this.bgVideoTimeline = new VideoTimeline(this.bgVideoPlayer, this, choosenSequence.milestones);
    });

    this.bgVideoPlayer.enableClickToPlayStop();

    this.enableAudioSpacialization();

    this.createShadowPlane();
  }

  onUserReady() {
    this._bgVideoLoadPromise.then(() => {
      this.bgVideoTimeline.start();
    });
  }

  enableAudioSpacialization() {
    if (typeof window.AudioContext === 'undefined') {
      return;
    }

    this.bgVideoPlayer.once('video-timeupdate', () => {
      const elem = this.bgVideoPlayer._videoElem;
      const context = new AudioContext();
      const source = context.createMediaElementSource(elem);
      const pannerNode = context.createPanner();

      // raise volume if needed
      const gainNode = context.createGain();
      gainNode.gain.value = choosenSequence.videoAudioGain || 1;

      // update audio position in vertical space
      this.bgVideoPlayer.on('video-timeupdate', () => {
        const bb = elem.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const halfViewport = viewportHeight * 0.5;
        const halfVideo = bb.height * 0.5;
        const center = bb.top + halfVideo - halfViewport;
        const posY = center / viewportHeight;

        pannerNode.positionY.value = -posY * 2.25;
      });

      // wire everything
      source.connect(gainNode);
      gainNode.connect(pannerNode);
      pannerNode.connect(context.destination);
    });
  }
}

export default FullscreenVideoScene;
