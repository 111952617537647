import * as THREE from 'three';

import {
  // Rate,
  RadialVelocity,
  VectorVelocity,
  Vector3D,
  Alpha,
  Scale,
  Color,
  Texture,
} from 'three-nebula';

import heartTexturePath from '../../assets/heart.png';

import AssetsManager from '../../managers/AssetsManager.js';
import AbstractParticlesEffect from './AbstractParticlesEffect.js';

const COLOR_WHITE = new THREE.Color(0xFFFFFF);
const TEXTURE_HEART = AssetsManager.loadTextureFile(heartTexturePath);

class HeartsEffect extends AbstractParticlesEffect {

  constructor(pps = 6, lifetime = 2) {
    super(pps, lifetime);
  }

  // _getRate() {
  //   return new Rate(this._pps, 0.25);
  // }

  _getInitializers() {
    return super._getInitializers().concat([
      new VectorVelocity(new Vector3D(-0.1, 0.1, 0), 90),
      new Texture(THREE, TEXTURE_HEART, {
        blending: THREE.NormalBlending,
        alphaTest: 0.25,
      }),
    ]);
  }

  _getBehaviours() {
    return super._getBehaviours().concat([
      new Alpha(1, 0),
      new Scale(0.1, 0),
      new Color(COLOR_WHITE, COLOR_WHITE),
    ]);
  }
}

export default HeartsEffect;
